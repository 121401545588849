import Navigation from './components/Navigation';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import Landing from './components/Landing';
import Information from './components/Information';
import Rules from './components/Rules';
import Footer from './components/Footer';

const App = () => {
    const theme = {
        primary: '#00f5be',
        secondary: '#021424',
    };
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <Navigation />
            <Landing />
            <Information />
            <Rules />
            <Footer />
        </ThemeProvider>
    );
};

export default App;

const GlobalStyles = createGlobalStyle`
  body {
    font-family: 'Montserrat', sans-serif
  }

  a { 
    transition: .4s ease;
  }
`;
