import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Logo from '../images/logo.png';

const navLinks = [
    {
        title: 'Påmelding',
        ref: 'https://www.sharptickets.net/event/Dalalan2022',
    },
    {
        title: 'Informasjon',
        ref: '#informasjon',
    },
    {
        title: 'Regler',
        ref: '#regler',
    },
];

const Navigation = () => {
    const LIMIT = 790;

    const [currWidth, setCurrWidth] = useState(window.innerWidth);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        window.addEventListener('resize', updateWidth);
        return () => {
            window.removeEventListener('resize', updateWidth);
        };
    }, []);

    const updateWidth = () => {
        setCurrWidth(window.innerWidth);
    };

    const renderNavLinks = () => {
        return (
            <StyledUl>
                {navLinks.map((item) => {
                    return (
                        <a key={item.title} href={item.ref}>
                            <li>{item.title}</li>
                        </a>
                    );
                })}
            </StyledUl>
        );
    };

    const renderOpenMenu = () => {
        const renderLinks = () => {
            return (
                <ul>
                    {navLinks.map((item) => {
                        return (
                            <a key={item.title} href={item.ref}>
                                <li>{item.title}</li>
                            </a>
                        );
                    })}
                </ul>
            );
        };
        return open ? <StyledOpenMenu>{renderLinks()}</StyledOpenMenu> : null;
    };

    const renderMobileMenu = () => {
        return (
            <StyledMobileMenu>
                <i className='fal fa-bars' onClick={() => setOpen(!open)} />
                {renderOpenMenu()}
            </StyledMobileMenu>
        );
    };

    return (
        <StyledNav>
            <a href='/'>
                <img
                    alt='Dalalan Logo'
                    src={Logo}
                    title='Til toppen av siden'
                />
            </a>
            {currWidth >= LIMIT ? renderNavLinks() : renderMobileMenu()}
        </StyledNav>
    );
};

export default Navigation;

const StyledNav = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 5rem;
    background-color: ${({ theme }) => theme.secondary};

    img {
        max-height: 100px;
    }
`;

const StyledUl = styled.ul`
    display: flex;
    list-style: none;
    padding: 0;
    a {
        text-transform: uppercase;
        position: relative;
        font-weight: 500;
        margin: 0 0.25rem;
        padding: 0.75rem;
        color: #fff;
        text-decoration: none;
        :hover {
            color: ${({ theme }) => theme.primary};
        }
        &:nth-child(1) {
            background-color: ${({ theme }) => theme.primary};
            color: ${({ theme }) => theme.secondary};
            font-weight: 600;
            transition: top ease 0.15s;
            top: 0;
            :hover {
                top: -2px;
            }
        }
    }
`;

const StyledMobileMenu = styled.div`
    color: #ffffff;
    font-size: 2rem;
    position: absolute;
    right: 4rem;
    cursor: pointer;
`;

const StyledOpenMenu = styled.div`
    position: absolute;
    background-color: ${({ theme }) => theme.secondary};
    right: 20%;
    width: 250px;
    padding: 1rem;
    ul {
        list-style: none;
        padding: 0;
        li {
            margin: 1rem 0;
        }
        a {
            font-size: 2rem;
            color: #ffffff;
            text-decoration: none;
            :hover {
                color: ${({ theme }) => theme.primary};
            }
        }
    }
`;
