import React from 'react';
import styled from 'styled-components';
import background from '../images/overblikk1.jpg';

const arrangementInfo = [
    {
        text: '16:00 - 18 Februar',
        icon: 'fal fa-hourglass-start',
    },
    {
        text: '12:00 - 20 Februar',
        icon: 'fal fa-hourglass-end',
    },
    {
        text: 'Storfjord Kulturhus',
        icon: 'fal fa-map-pin',
    },
];

const Landing = () => {
    const renderArrangementInfo = () => {
        return (
            <ul>
                {arrangementInfo.map((item) => {
                    return (
                        <li key={item.text}>
                            <i className={item.icon} /> {item.text}
                        </li>
                    );
                })}
            </ul>
        );
    };
    return (
        <StyledWrapper>
            <StyledBackground>
                <StyledCardContainer>
                    <StyledMainCard>
                        <h1>
                            Velkommen til <span>Dalalan</span>
                        </h1>
                        <p>
                            Dalalan arrangeres på vestlandets flotteste bygd,
                            Stranda, og har vært arrangert nesten hvert år i
                            over 10 år!
                        </p>
                        <p>
                            Ønsker du å delta på en minnerik opplevelse? Bli med
                            da vell!
                        </p>
                    </StyledMainCard>
                    <StyledArrangementCard>
                        <span>Neste Arrangement</span>
                        <h2>Dalalan Winter 2022</h2>
                        {renderArrangementInfo()}
                        {/* Insert Google Maps location pin and Facebook Link */}

                        <StyledContactText>
                            Har du spørsmål?{' '}
                            <a
                                rel='noreferrer'
                                href='https://www.facebook.com/Dalalanparty'
                                target='_blank'
                            >
                                Ta kontakt!
                            </a>
                        </StyledContactText>
                        <StyledFacebookIcon
                            rel='noreferrer'
                            target='_blank'
                            href='https://www.facebook.com/events/354788876331328'
                        >
                            <i className='fab fa-facebook-square' />
                        </StyledFacebookIcon>
                    </StyledArrangementCard>
                </StyledCardContainer>
            </StyledBackground>
        </StyledWrapper>
    );
};

export default Landing;

const StyledWrapper = styled.section`
    height: 87vh;
    h1,
    p {
        margin: 0;
    }
`;

const StyledBackground = styled.div`
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100%;
    background: rgb(3, 15, 25, 0.8);
    background: linear-gradient(
            180deg,
            rgba(2, 20, 36, 1) 10%,
            rgba(5, 65, 58, 0.8) 67%,
            rgba(0, 166, 129, 1) 100%
        ),
        url(${process.env.PUBLIC_URL}${background});

    display: flex;
    justify-content: center;
`;

const StyledCardContainer = styled.div`
    height: 100%;
    width: 100%;
    margin: 6.25rem;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1380px) {
        flex-direction: column;
        margin: 4.5rem;
    }
    @media screen and (max-width: 620px) {
        margin: 2.5rem;
    }
`;

const StyledMainCard = styled.div`
    h1 {
        color: #fff;
        font-size: 2.75rem;
        margin-bottom: 1.5rem;
        span {
            color: ${({ theme }) => theme.primary};
        }
    }
    p {
        color: #d6d6d6;
        font-weight: 300;
        margin-bottom: 1rem;
    }
`;

const StyledArrangementCard = styled.div`
    /* background-color: ${({ theme }) => theme.secondary}; */
    background-color: #02101c;
    -webkit-box-shadow: 0px 0px 10px 4px #02101c;
    box-shadow: 0px 0px 4px 10px #02101c;
    padding: 2rem;
    h2 {
        color: ${({ theme }) => theme.primary};
        margin-top: 0.25rem;
    }

    span {
        color: #fff;
        font-weight: lighter;
    }

    ul {
        list-style: none;
        padding: 0;

        li {
            color: #fff;
            margin: 0.5rem 0;
            & i {
                color: ${({ theme }) => theme.primary};
                width: 20px;
            }
        }
    }

    @media screen and (max-width: 1380px) {
        margin-top: 2rem;
    }
`;

const StyledContactText = styled.p`
    color: #ffffff;
    a {
        color: ${({ theme }) => theme.primary};
    }
`;

const StyledFacebookIcon = styled.a`
    display: flex;
    margin-top: 1rem;
    justify-content: center;
    text-decoration: none;
    i {
        font-size: 2rem;
        text-align: center;
        color: ${({ theme }) => theme.primary};
    }
`;
