import React from 'react';
import styled from 'styled-components';

const regler = [
    {
        nummer: 1,
        tekst: 'Dalalan er eit rusfritt arrangement. Ruspåvirka personar vil bli bortvist frå arrangementet, og eventuelle rusmiddel vil bli konfiskert.',
    },
    {
        nummer: 2,
        tekst: 'Røyking er ikkje tillete i lokalet, og skal kun foregå på anvist plass utanfor bygget. Om du snusar skal brukte snusklyser i søpla.',
    },
    {
        nummer: 3,
        tekst: 'Når du ankjem Dalalan skal du aller først betale for deg og få tildelt band. Bandet er du sjølv ansvarleg for, mistar du det må du betale for eit nytt. Vis ALLTID bandet når du har vore ute av lokalet.',
    },
    {
        nummer: 4,
        tekst: 'Plassen du har valgt på Reservering er din plass under heile arrangementet. Om du vil bytte plass må du ta kontakt med Crew.',
    },
    {
        nummer: 5,
        tekst: 'Vi har kiosk i salen med godt utvalg og god mat. I tillegg har vi ein microovn som deltakakrar kan benytte seg av. Oppvarming av medbrakt pizza skjer igjennom crewet.',
    },
    {
        nummer: 6,
        tekst: 'Deltakarane skal sørge for å halde det ryddig rundt plassen sin. Crew kan pålegge deltakarane å rydde rundt sin egen plass. Crew vil gå runder og samle inn pant og søppel med jevne mellomrom.',
    },
    {
        nummer: 7,
        tekst: 'Soving skal berre foregå på eget soveområder. Vi anbefaler heller ikkje å sove sittande på stolane med tanke på eiga helse. Det er høgst anbefalt av arrangørane at ein tar med sovemateriell som liggeunderlag/feltseng og sovepose/pledd.',
    },
    {
        nummer: 8,
        tekst: 'Det er forventa at du utøver vanlig folkeskikk og følger norsk lov under arrangementet. Respekter dei rundt deg eller aksepter konsekvensane. Lovbrot og herverk vil bli politianmeldt.',
    },
    {
        nummer: 9,
        tekst: 'Deltakarane får ein plass på «ukjent» til disposisjon. Alt du får plass til av datamaskin, konsoll og TV/skjerm er i prinsippet tillete. Kvitevarer som vannkokar, kjøleskap og vaffeljern er ikkje tillete.',
    },
    {
        nummer: 10,
        tekst: 'Eit straumuttak og eit nettverksuttak vert gjort tilgjengeleg pr. deltakar. I tillegg prøvar vi å halde eit fungerande WLAN. Om straumen går på ei rekke kan crew kreve at unødig utstyr vert fjerna.',
    },
    {
        nummer: 11,
        tekst: 'Du er sjølv ansvarlig for eige utstyr. La ikkje verdigjenstandar ligge synleg om ikkje du eller nokon du stolar på er i nærleiken. Vi har ikkje hatt problemer med tjuveri på våre arrangement, men bedre føre var. Sørg og for å ha oppdatert antivirusprogram, for di eiga skuld sovel som  andre.',
    },
    {
        nummer: 12,
        tekst: 'Dyr er ikkje tillete i lokalet grunna allergiar.',
    },
    {
        nummer: 13,
        tekst: 'Det er naturleg nok ikkje tillete å snike seg inn i lokalet. Oppdagar vi dette vil du bli utestengd på ubestemt tid. Prøvar du som deltakar å ta deg inn i rusa tilstand vil du bli bortvist og får hente utstyret ditt tidlegast neste ag, då i følge med foresatt.',
    },
    {
        nummer: 14,
        tekst: 'Skulle det oppstå eit naudstilfelle, grip tak i næraste crewmedlem og gjer dei kjent med situasjonen. Vi har personell trent i alt frå førstehjelp til sjokoladesuffle.',
    },
    {
        nummer: 15,
        tekst: 'Kos deg og ha det morro. Om noko eller nokon plagar deg undervegs er det berre å ta kontakt med crew. Vi er her for dykk.',
    },
];

const Rules = () => {
    const renderRules = () => {
        return (
            <ul>
                {regler.map((regler) => {
                    return (
                        <li key={regler.nummer}>
                            <span>§ {regler.nummer}</span> {regler.tekst}
                        </li>
                    );
                })}
            </ul>
        );
    };
    return (
        <StyledContainer id='regler'>
            <h1>Regler</h1>
            {renderRules()}
        </StyledContainer>
    );
};

export default Rules;

const StyledContainer = styled.section`
    text-align: center;
    padding: 0 6rem;
    margin-bottom: 4rem;
    ul {
        list-style: none;
        padding: 0;
        li {
            span {
                color: ${({ theme }) => theme.primary};
                font-size: 1.25rem;
            }
            margin: 1rem 0;
        }
    }

    h1 {
        font-size: 2.5rem;
    }

    @media screen and (max-width: 764px) {
        padding: 0 2.5rem;
    }
`;
