import React from 'react';
import styled from 'styled-components';

const Footer = () => {
    return (
        <StyledFooter>
            <div>
                <i className='fab fa-facebook' />
                <i className='fab fa-discord' />
            </div>
            <p>Org.Nr 927 809 370</p>
        </StyledFooter>
    );
};

export default Footer;

const StyledFooter = styled.footer`
    width: 100%;
    height: 100px;
    background: ${({ theme }) => theme.secondary};
    position: absolute;
    text-align: center;
    padding-top: 2rem;
    color: #fff;
    i {
        font-size: 2rem;
        margin: 0 1rem;
        transition: 0.2s ease;
        :hover {
            color: ${({ theme }) => theme.primary};
            cursor: pointer;
        }
    }
`;
