import React from 'react';
import styled from 'styled-components';
import background from '../images/person1.jpg';

const Information = () => {
    return (
        <StyledSection>
            <StylerWrapper id='informasjon'>
                <StyledContainer>
                    <h1>Informasjon</h1>
                    <StyledCardContainer>
                        <StyledCard>
                            <h2>Priser</h2>
                            <p>Deltager: 400 kr</p>
                            <p>Besøk Fredag: 100 kr</p>
                            <p>Besøk Lørdag: 100 kr</p>
                            <p>Besøk Helg: 180 kr</p>
                        </StyledCard>
                        <StyledCard>
                            <h2>Mat</h2>
                            <p>Mer informasjon kommer.</p>
                        </StyledCard>
                        <StyledCard>
                            <h2>Aldersgrense</h2>
                            <p>
                                Det er 13 års aldersgrense. Yngre kan delta ved
                                skriftlig samtykke fra foreldre.
                            </p>
                        </StyledCard>
                        <StyledCard>
                            <h2>Internett</h2>
                            <p>Mer informasjon kommer.</p>
                        </StyledCard>
                    </StyledCardContainer>
                </StyledContainer>
            </StylerWrapper>
        </StyledSection>
    );
};

export default Information;

const StyledSection = styled.section`
    height: 100vh;
`;

const StylerWrapper = styled.div`
    height: 100%;
    background: rgb(255, 255, 255);
    background-repeat: no-repeat;
    background-size: auto;
    background-image: url(${process.env.PUBLIC_URL}${background});
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(207, 238, 231, 0) 29%,
        rgba(142, 215, 199, 02) 69%,
        rgba(87, 196, 172, 1) 89%,
        rgba(0, 166, 129, 1) 100%
    );
`;

const StyledContainer = styled.div`
    padding-top: 6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
        margin: 0;
        color: ${({ theme }) => theme.secondary};
        font-size: 2.75rem;
        text-align: center;
    }
`;

const StyledCardContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 3rem;
    margin: 4rem 6.25rem;
    @media screen and (max-width: 764px) {
        display: flex;
        flex-direction: column;
        margin: 4rem 2.5rem;
    }
`;

const StyledCard = styled.div`
    background-color: #02101c;
    -webkit-box-shadow: 0px 0px 10px 4px #02101c;
    box-shadow: 0px 0px 4px 10px #02101c;
    padding: 0 2rem;
    h2,
    p {
        color: #fff;
    }
`;
